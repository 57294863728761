<language-widget>

    <div>
        <div>
            <div class="accessibility-icon-container-lang" if="{props.langModeIcon}">
                <div class="tp-lang-modal-icon" style="{getTopVar(props.widgetType)}" onclick="{showSideLang}">
                    <div class="iconCode" if="{props.langModeIcon == 'option2'}">
                        {activeLang()?.language_code}
                    </div>
                    <div>
                        <img class="iconFlag" width="48" height="48" if="{props.langModeIcon == 'option1'}"
                            src="{loadFile(activeLang()?.image)}" alt="">
                    </div>
                    <div class="iconWorld" if="{props.langModeIcon == 'option3'}">
                        <img width="36" height="36" src="{window.tamkin_src_base+'/images/ground.svg'}" alt="">
                    </div>
                </div>
            </div>

            <div class="tp-accessibility-lang" id="tp-accessibility-lang">
                <div class="tp-accessibility-lang-header text-white border-bottom
                    d-flex align-items-center justify-content-between">
                    <p class="font-16 font-w500 mb-0">
                        Live Translator
                    </p>
                    <a onclick="{showSideLang}" class="tp-accessibility-lang-close rounded-circle"
                        href="javascript:void(0)">
                        <div></div>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18" stroke="white" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                            <path d="M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </a>

                </div>

                <!-- body -->
                <div class="tp-lang-sidebar-body">
                    <div class="mx-3 mt-3" id="languages-box">
                        <div class="form-group mb-3 px-1" id="tp-search-gp">
                            <label for="tp-search"><img class="h-25px"
                                    src="{window.tamkin_src_base+'/images/icon-search.svg'}" alt="search"></label>
                            <input oninput="{searchLanguages}" type="text" id="tp-search"
                                class="form-control rounded-4 ps-5 h-50px" placeholder="Search by Keyword...">
                            <span if="{ document.getElementById('tp-search').value.length> 0}"
                                onclick="{(e) => removeSearchLanguageTrim(e)}" id="tp-search-remove">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 6L6 18" stroke="#585B5B" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path d="M6 6L18 18" stroke="#585B5B" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg>
                            </span>
                        </div>
                        <hr class="mb-2">
                        <div if="{ state.filteredLanguages.length}" class=" list-group list-group-flush notranslate">
                            <a each="{(lang, index) in state.filteredLanguages}" id="g-lang-{lang.language_code}"
                                key="{lang.language_code}" href="javascript:void(0)"
                                onclick="{(e) => changeLanguage(e, lang)}"
                                class="{(activeLang())?.language_code && lang.language_code==(activeLang()).language_code?'active':''} px-4 
                                list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                                <div class="d-flex g-lang-inside">
                                    <div style="text-transform: capitalize;" class="rounded-circle tp-icon text-center"
                                        if="{props.langModeIcon == 'option2'}">
                                        <span>{lang.language_code}</span>
                                    </div>
                                    <div class="rounded-circle text-center" if="{props.langModeIcon == 'option1'}">
                                        <img width="30" height="26" src="{loadFile(lang.image)}" alt="">
                                    </div>
                                    <div if="{props.langModeIcon == 'option3'}"
                                        class="rounded-circle tp-icon text-center worldbg d-flex align-items-center justify-content-center">
                                        <img width="26" height="26" src="{window.tamkin_src_base+'/images/ground.svg'}"
                                            alt="">
                                    </div>
                                    <span
                                        class="px-2 font-16 font-w400 mb-0 align-content-center">{lang.language_name}</span>
                                </div>
                            </a>
                        </div>

                        <div class=" list-group" if="{ state.filteredLanguages.length == 0}">
                            <div class="noDataFound" id="noDataFound">
                                <img class="nodata" src="{window.tamkin_src_base+'/images/noData.svg'}" alt="noData">
                                <h3>No Results Found </h3>
                                <p>Please check your spelling and try again.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tp-accessibility-sidebar-footer"
                    style="background-image: url('/images/acc-side-footer-bg.svg')">
                    <div class="d-flex align-items-center justify-content-between py-2 px-3">
                        <a class="d-block" href="{props.accessibility_settings?.accessibility_settings?.tamkin_url}"
                            target="_blank">
                            <img src="{window.tamkin_src_base+'/images/acc-side-footer-logo.svg'}" alt="Tamkin">
                        </a>
                        <div class="text-end">
                            <p class="mb-1 font-14 font-w400">Report a problem</p>
                            <a href="mailto:{props.accessibility_settings?.accessibility_settings?.support_email}"
                                class="btn btn-primary tp-bg-primary border-0 px-4 btn-sm rounded-4 font-14 font-w400">Report</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <script>
            import "/src/player_files/accessibility/bootstrap.min.js";
            export default {
                components: {
                },
                state: {
                    defaultLang: { 'language_name': 'English (USA)', 'language_code': 'en', 'language_code_display': 'EN', 'image': `/files/us0d2edf.svg` },
                    languages: [],
                    filteredLanguages: [],
                },

                showSideLang() {
                    $("#tp-accessibility-lang").toggleClass("active")
                },

                getTopVar(type) {
                    if (type === 'full-widget') {
                        return `top: calc(var(--tp-accessibility-top) - 100px);`;
                    } else if (type === 'mini-widget') {
                        return `top: calc(var(--tp-accessibility2-top) - 100px);`;
                    } else if (type === 'minuscule-widget') {
                        return `top: calc(var(--tp-accessibility3-top) - 100px);`;
                    } else {
                        return `top: calc(var(--tp-accessibility4-top) - 100px);`;
                    }
                },

                activeLang() {
                    let activeLang = this.state.filteredLanguages.find(lang => lang.active);
                    return activeLang ?? this.state.defaultLang
                },
                onBeforeMount(props, state) {
                    fetch('https://tamkin.app/api/method/tamkin_website.api.getAccessibilityData')
                        .then(response => {
                            if (!response.ok)
                                throw new Error(`HTTP error! Status: ${response.status}`);
                            return response.json();
                        })
                        .then(data => {
                            this.update({
                                languages: data.message.languages,
                                filteredLanguages: data.message.languages,
                            });
                        })
                        .catch(error => { });

                },
                onMounted(props, state) {

                    $('body > *:not(#tamkinPlayerApp,tamkin-player-sdk, #tamkinPlayerApp *,tamkin-player-sdk *)').on('click', () => {
                        $("#tp-accessibility-lang").removeClass("active")
                    });

                    // google trans
                    var selectBoxContainer = document.querySelector("#google_translate_element");
                    if (selectBoxContainer) {
                        var $this = this;
                        selectBoxContainer.addEventListener('elementLoaded', () => {
                            var selectBox = document.querySelector("#google_translate_element select.goog-te-combo");
                            let secondsPassed = 0;
                            let langInterval = setInterval(() => {
                                if (selectBox.value) {
                                    $this.activateLanguage(selectBox.value);
                                    clearInterval(langInterval);
                                }
                                if (secondsPassed > 5) {
                                    clearInterval(langInterval);
                                }
                                secondsPassed++;
                            }, 1000);
                        })
                    }
                },
                activateLanguage(active_lang) {
                    // check lang_name is string or object
                    let activeLanguage;
                    if (typeof active_lang == 'string') {
                        activeLanguage = this.state.languages.find(l => l.language_code == active_lang);
                    } else {
                        activeLanguage = active_lang
                    }

                    // set lang as active and deactivate others
                    this.state.filteredLanguages.forEach(function (l) { if (l.active) { l.active = false } });
                    activeLanguage.active = true;
                    this.update()

                    // clone the active language html to inside #g-lang-active (accordion btn)
                    let activeLangHTML = $("#g-lang-" + activeLanguage.language_code + " .g-lang-inside").html()
                    $("#g-lang-active .g-lang-inside").html(activeLangHTML);

                    // close the language accordion
                    if ($("#languages-box").hasClass('show')) {
                        $("#languages-box").removeClass('show')
                        $("#g-lang-active").attr('aria-expanded', 'false').removeClass('collapsed');
                    }

                    // rtl sidebar
                    setTimeout(() => {
                        if ($('html').hasClass('translated-rtl')) {
                            $(' #tp-accessibility-sidebar').addClass('rtl');
                        } else {
                            $(' #tp-accessibility-sidebar').removeClass('rtl');
                        }
                    }, 500);
                },
                onUpdated(props, state) {

                },
                searchLanguages(e) {
                    let search = $(e.target).val();
                    this.update({ filteredLanguages: this.state.languages.filter(lang => lang.language_name.toLowerCase().includes(search.toLowerCase())) });
                },
                removeSearchLanguageTrim(e) {
                    $("#tp-search").val('')
                    this.update({ filteredLanguages: this.state.languages })
                },
                loadFile(path) {
                    return 'https://tamkin.app' + path;
                },
                changeLanguage(e, lang) {
                    var selectBox = document.querySelector("#google_translate_element select.goog-te-combo");
                    if (selectBox && selectBox.value != lang.language_code) {
                        // trigger google change translate the website
                        selectBox.value = lang.language_code;
                        var event = new Event('change', { 'bubbles': true, 'cancelable': true });
                        selectBox.dispatchEvent(event);

                        $('body *:not(div.notranslate *)').each(function () {
                            if ($(this).children().length === 0 && $(this).text().trim() !== '') {
                                $(this).addClass('highlight');
                            }
                        });

                        // sometimes this doesn't work, so i try to trigger it again depending on html tag has eigher 'translated-ltr' or 'translated-rtl'
                        setTimeout(() => {
                            $('.highlight').removeClass('highlight');
                            if (!$('html').hasClass('translated-rtl') && !$('html').hasClass('translated-ltr')) {
                                $(e.target).trigger('click');
                            }

                            this.activateLanguage(lang);
                        }, 1500);

                    }
                },


            }
        </script>

        <style>
            .tp-lang-modal-icon {
                position: fixed;
                right: 0;
                cursor: pointer;
                z-index: 1041;
            }

            .accessibility-icon-container-lang.ltr .tp-lang-modal-icon {
                left: 0;
                right: auto;
            }

            .tp-accessibility-lang {
                position: fixed;
                top: 0%;
                right: -100%;
                width: 350px;
                z-index: 1042;
                height: 100vh;
                background-color: #FFF;
                transition: all 0.7s;
                border-radius: 8px;
            }

            @media only screen and (max-width: 460px) {
                .tp-accessibility-lang {
                    width: 100% !important;
                }
            }

            .tp-accessibility-lang.active {
                right: 0px;
            }

            .tp-accessibility-lang.ltr {
                transition: all 0.7s;
            }

            .tp-accessibility-lang.ltr.active {
                left: 0px;
                right: auto;
                transition: all 0.7s;
            }

            .tp-accessibility-lang-header {
                height: 56px;
                /* background: linear-gradient(180deg,
                        var(--tp-primary) 0%,
                        var(--tp-primary-grad) 100%); */
                background-color: var(--tp-primary);
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                border-top-right-radius: 0px;
                border-top-left-radius: 0px;
                padding: 0px 15px;
            }

            .worldbg {
                width: 36px;
                height: 36px;
                border-radius: 50%;
                /* background: linear-gradient(180deg,
                        var(--tp-primary) 0%,
                        var(--tp-primary-grad) 100%); */
                background-color: var(--tp-primary);
            }

            /* .tp-accessibility-lang.ltr .tp-accessibility-lang-header {
                margin-right: auto;
            } */


            .tp-accessibility-lang-close {
                background-color: white;
                width: 35px;
                height: 35px;
                transition: all 0.5s;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .tp-accessibility-lang-close div {
                background-color: #585b5b;
                text-decoration: none;
                width: 20px;
                height: 3px;
                border-radius: 2px;
            }

            .tp-accessibility-lang-close:hover {
                /* background: linear-gradient(180deg,
                        var(--tp-primary) 0%,
                        var(--tp-primary-grad) 100%); */
                background-color: var(--tp-primary);
                box-shadow: 1px 0px 10.6px 0px var(--tp-primary-grad);
            }

            .tp-accessibility-lang-close svg {
                display: none;
            }

            .tp-accessibility-lang-close:hover svg {
                display: block;
            }

            .tp-accessibility-lang-close:hover div {
                display: none;
            }


            /* accordion */
            .list-group-flush>.list-group-item:last-child {
                border-width: 0 0 var(--bs-list-group-border-width);
            }

            .tp-icon {
                width: 36px !important;
                height: 36px !important;
                align-content: center;
                text-align: center;
            }

            .tp-icon.lg {
                width: 50px !important;
                height: 50px !important;
            }

            .tp-icon.sm {
                width: 28px !important;
                height: 28px !important;
            }

            #languages-box .form-group label {
                position: absolute;
                top: 13px;
                left: 1rem;
            }

            #languages-box .list-group-item .tp-icon {
                background-color: var(--tp-primary-light);
                color: var(--tp-primary);
            }

            #languages-box .list-group-item.active .tp-icon,
            #g-lang-active .tp-icon {
                background-color: var(--tp-primary);
                color: white;
            }

            #languages-box .list-group-item.active {
                background-color: var(--tp-primary-light);
                border-color: var(--tp-primary-light);
                color: var(--tp-dark);
            }

            .collapsing {
                transition: all 0.1s !important;
            }

            #languages-box .list-group {
                height: calc(100vh - 56px - 181px);
                overflow-y: auto;
                scrollbar-width: thin;
                scrollbar-color: var(--tp-primary) #f1f1f1;
            }

            #languages-box .list-group-item.active::before {
                content: "";
                position: absolute;
                right: 24px;
                top: 14px;
                width: 24px;
                height: 24px;
                background-size: contain;
                background-repeat: no-repeat;
            }

            .rtl #languages-box .list-group-item.active::before {
                right: auto;
                left: 24px;
            }

            .noDataFound {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                justify-content: start;
                align-items: center;
                gap: 8px;
            }

            .noDataFound h3 {
                font-size: 13px;
                font-weight: 600;
                line-height: 19.5px;
                letter-spacing: 0.30000001192092896px;
                text-align: center;
            }

            .noDataFound p {
                font-size: 10px;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: 0.30000001192092896px;
                text-align: center;
            }
        </style>
</language-widget>