<accessibility-sidebar>
    <div class="tp-accessibility3-sidebar  no-style-scroll" id="tp-accessibility3-sidebar">
        <!-- header -->
        <div class="tp-accessibility3-sidebar-header  p-4 d-flex align-items-center justify-content-center">

            <a class="tp-accessibility3-sidebar-close rounded-circle" href="javascript:void(0)" onclick="{closeAcc}">
                <div></div>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18" stroke="white" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path d="M6 6L18 18" stroke="white" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </a>


        </div>
        <!-- body -->
        <div class="tp-accessibility3-sidebar-body tp-style-scroll">
            <div class="mt-">
                <accessibility-controls widget_type="minuscule_widget" cols='col-12'
                    if='{state.accessabilityFeatures.length}' enabledFeatures="{state.accessabilityFeatures}"
                    enabledFeaturesNames="{state.enabledFeaturesNames}" sidebarPosition="{state.sidebarPosition}">
                </accessibility-controls>
            </div>
        </div>


    </div>

    <script>
        import sound from "/src/player_files/audio/open_audio.mp3"

        import AccessibilityControls from '/src/accessibility/full_widget/accessibility-controls.riot';
        export default {
            components: {
                'accessibility-controls': AccessibilityControls,
            },
            state: {
                sidebarPosition: 'right',
                enabledFeatures: [],
                enabledFeaturesNames: [],
                defaultLang: { 'language_name': 'English (English)', 'language_code': 'en', 'language_code_display': 'EN', 'image': `/files/us0d2edf.svg` },
                languages: [],
                filteredLanguages: [],
                accessabilityFeatures: [],
                languageFeature: {},
                activeLanguageIcon: '',
                accessabilityProfiles: [],
                makeHighlight: true,
                observer2: ""
            },

            closeAcc() {
                $(".tp-accessibility3").css("display", 'block')
                document.querySelector('#tamkinPlayerApp #tp-accessibility3-sidebar').classList.remove('active');
                const soundeffect = localStorage.getItem("enableSoundEffect");
                const audio = new Audio(sound);
                if (soundeffect == 1) {
                    audio.play();
                }
            },
            activeLang() {
                let activeLang = this.state.filteredLanguages.find(lang => lang.active);
                return activeLang ?? this.state.defaultLang
            },
            onBeforeUnmount() {
                if (this.observer2) {
                    this.observer2.disconnect();
                }
            },
            onBeforeMount(props, state) {
                fetch('https://api.tamkin.app/v1/api/Widget/GetAccessibility')
                    .then(response => {
                        if (!response.ok)
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        return response.json();
                    })
                    .then(data => {

                        this.update({
                            languages: data.data.languages,
                            filteredLanguages: data.data.languages,
                            enabledFeatures: data.data.features,
                            enabledFeaturesNames: data.data.features.find(feature => feature.name === 'acc-addons-main-menu')?.features.map(feature => feature.name),
                            languageFeature: data.data.features.find(feature => feature.name === 'acc-customize-translations-button')?.features,
                            sidebarPosition: data.data.features.find(feature => feature.name === 'acc-customize-accessibility-mode')?.features[0]?.value,
                            accessabilityProfiles: data.data.features.find(feature => feature.name === "acc-addons-accessibility-profiles").features?.filter(el => {
                                if (el.value == 1 && el.active == 1) {
                                    return el
                                }
                            }),
                            accessabilityFeatures: data.data.features.find(feature => feature.name === 'acc-addons-main-menu')?.features?.filter(el => {
                                if (el.value == 1 && el.active == 1) {
                                    return el
                                }
                            }),
                        });

                        // this.setSidebarPositionDom(this.sidebarIsLeft() ? 'left' : 'right');
                        this.setSidebarPositionDom(localStorage.getItem("tpSidebarPosition") ? localStorage.getItem("tpSidebarPosition") : this.state.sidebarPosition);


                        // widget location 
                        if (window.visualViewport.width > 500) {
                            const obj = this.state.enabledFeatures.
                                find(feature => feature.name === "acc-customize-button-location")
                                ?.features.find(el => el.name == "acc-customize-button-location-button-location-desktop");
                            const desktopLocation = obj.value

                            const tamkinPlayerApp = document.getElementById('tamkinPlayerApp');
                            if (obj.active == 1) {
                                if (desktopLocation === 'postion1') {
                                    if (localStorage.getItem("tpSidebarPosition") === 'left') {
                                        $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                        $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                        $('#tamkinPlayerApp #tp-accessibility3-sidebar').addClass('ltr');
                                        $('#tamkinPlayerApp .accessibility-icon-container').addClass('ltr');
                                    }

                                    tamkinPlayerApp.style.setProperty('--tp-accessibility3-top', '25%');
                                } else if (desktopLocation === 'postion2') {
                                    if (localStorage.getItem("tpSidebarPosition") === 'left') {
                                        $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                        $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                        $('#tamkinPlayerApp #tp-accessibility3-sidebar').addClass('ltr');
                                        $('#tamkinPlayerApp .accessibility-icon-container').addClass('ltr');
                                    }
                                    tamkinPlayerApp.style.setProperty('--tp-accessibility3-top', '50%');
                                } else if (desktopLocation === 'postion3') {
                                    if (localStorage.getItem("tpSidebarPosition") === 'left') {
                                        $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                        $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                        $('#tamkinPlayerApp #tp-accessibility3-sidebar').addClass('ltr');
                                        $('#tamkinPlayerApp .accessibility-icon-container').addClass('ltr');
                                    }
                                    tamkinPlayerApp.style.setProperty('--tp-accessibility3-top', '80%');
                                } else if (desktopLocation === 'postion4') {
                                    $('#tamkinPlayerApp .accessibility-icon-container').removeClass('ltr');
                                    $('#tamkinPlayerApp #tp-accessibility3-sidebar').removeClass('ltr');
                                    $('#tamkinPlayerApp .tp-lang-modal-icon').css('right', '48%');
                                    $("#tamkinPlayerApp .accessibility-icon-container .tp-accessibility3").css('right', '48%')
                                    $("#tamkinPlayerApp .accessibility-icon-container .tp-accessibility3-text").css('display', 'none')
                                    tamkinPlayerApp.style.setProperty('--tp-accessibility3-top', '25%');
                                } else if (desktopLocation === 'postion5') {
                                    $('#tamkinPlayerApp .accessibility-icon-container').removeClass('ltr');
                                    $('#tamkinPlayerApp #tp-accessibility3-sidebar').removeClass('ltr');
                                    $('#tamkinPlayerApp .tp-lang-modal-icon').css('right', '48%');
                                    $("#tamkinPlayerApp .accessibility-icon-container .tp-accessibility3").css('right', '48%')
                                    $("#tamkinPlayerApp .accessibility-icon-container .tp-accessibility3-text").css('display', 'none')
                                    tamkinPlayerApp.style.setProperty('--tp-accessibility3-top', '80%');
                                } else if (desktopLocation === 'postion6') {
                                    if (localStorage.getItem("tpSidebarPosition") === 'right') {
                                        $('#tamkinPlayerApp .accessibility-icon-container').removeClass('ltr');
                                        $('#tamkinPlayerApp #tp-accessibility3-sidebar').removeClass('ltr');
                                    } else {
                                        $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                        $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                        $('#tamkinPlayerApp #tp-accessibility3-sidebar').addClass('ltr');
                                        $('#tamkinPlayerApp .accessibility-icon-container').addClass('ltr');
                                    }
                                    tamkinPlayerApp.style.setProperty('--tp-accessibility3-top', '25%');
                                } else if (desktopLocation === 'postion7') {
                                    if (localStorage.getItem("tpSidebarPosition") === 'right') {
                                        $('#tamkinPlayerApp .accessibility-icon-container').removeClass('ltr');
                                        $('#tamkinPlayerApp #tp-accessibility3-sidebar').removeClass('ltr');
                                    } else {
                                        $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                        $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                        $('#tamkinPlayerApp #tp-accessibility3-sidebar').addClass('ltr');
                                        $('#tamkinPlayerApp .accessibility-icon-container').addClass('ltr');
                                    }
                                    tamkinPlayerApp.style.setProperty('--tp-accessibility3-top', '50%');
                                } else if (desktopLocation === 'postion8') {
                                    if (localStorage.getItem("tpSidebarPosition") === 'right') {
                                        $('#tamkinPlayerApp .accessibility-icon-container').removeClass('ltr');
                                        $('#tamkinPlayerApp #tp-accessibility3-sidebar').removeClass('ltr');
                                    } else {
                                        $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                        $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                        $('#tamkinPlayerApp #tp-accessibility3-sidebar').addClass('ltr');
                                        $('#tamkinPlayerApp .accessibility-icon-container').addClass('ltr');
                                    }
                                    tamkinPlayerApp.style.setProperty('--tp-accessibility3-top', '80%');
                                }
                            }

                        } else {
                            const obj = this.state.enabledFeatures.
                                find(feature => feature.name === "acc-customize-button-location")
                                ?.features.find(el => el.name == "acc-customize-button-location-button-location-mobile");
                            const mobileLocation = obj.value;
                            const tamkinPlayerApp = document.getElementById('tamkinPlayerApp');
                            if (obj.active == 1) {
                                if (mobileLocation === 'postion1') {
                                    if (localStorage.getItem("tpSidebarPosition") === 'left') {
                                        $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                        $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                        $('#tamkinPlayerApp #tp-accessibility3-sidebar').addClass('ltr');
                                        $('#tamkinPlayerApp .accessibility-icon-container').addClass('ltr');
                                    }
                                    tamkinPlayerApp.style.setProperty('--tp-accessibility3-top', '25%');
                                } else if (mobileLocation === 'postion2') {
                                    if (localStorage.getItem("tpSidebarPosition") === 'left') {
                                        $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                        $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                        $('#tamkinPlayerApp #tp-accessibility3-sidebar').addClass('ltr');
                                        $('#tamkinPlayerApp .accessibility-icon-container').addClass('ltr');
                                    }
                                    tamkinPlayerApp.style.setProperty('--tp-accessibility3-top', '50%');
                                } else if (mobileLocation === 'postion3') {
                                    if (localStorage.getItem("tpSidebarPosition") === 'left') {
                                        $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                        $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                        $('#tamkinPlayerApp #tp-accessibility3-sidebar').addClass('ltr');
                                        $('#tamkinPlayerApp .accessibility-icon-container').addClass('ltr');
                                    }
                                    tamkinPlayerApp.style.setProperty('--tp-accessibility3-top', '80%');
                                } else if (mobileLocation === 'postion4') {
                                    if (localStorage.getItem("tpSidebarPosition") === 'right') {
                                        $('#tamkinPlayerApp .accessibility-icon-container').removeClass('ltr');
                                        $('#tamkinPlayerApp #tp-accessibility3-sidebar').removeClass('ltr');
                                    } else {
                                        $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                        $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                        $('#tamkinPlayerApp #tp-accessibility3-sidebar').addClass('ltr');
                                        $('#tamkinPlayerApp .accessibility-icon-container').addClass('ltr');
                                    }
                                    tamkinPlayerApp.style.setProperty('--tp-accessibility3-top', '25%');
                                } else if (mobileLocation === 'postion5') {
                                    if (localStorage.getItem("tpSidebarPosition") === 'right') {
                                        $('#tamkinPlayerApp .accessibility-icon-container').removeClass('ltr');
                                        $('#tamkinPlayerApp #tp-accessibility3-sidebar').removeClass('ltr');
                                    } else {
                                        $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                        $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                        $('#tamkinPlayerApp #tp-accessibility3-sidebar').addClass('ltr');
                                        $('#tamkinPlayerApp .accessibility-icon-container').addClass('ltr');
                                    }
                                    tamkinPlayerApp.style.setProperty('--tp-accessibility3-top', '50%');
                                } else if (mobileLocation === 'postion6') {
                                    if (localStorage.getItem("tpSidebarPosition") === 'right') {
                                        $('#tamkinPlayerApp .accessibility-icon-container').removeClass('ltr');
                                        $('#tamkinPlayerApp #tp-accessibility3-sidebar').removeClass('ltr');
                                    } else {
                                        $('#tamkinPlayerApp .accessibility-icon-container-lang').addClass('ltr');
                                        $('#tamkinPlayerApp .tp-accessibility-lang').addClass('ltr');
                                        $('#tamkinPlayerApp #tp-accessibility3-sidebar').addClass('ltr');
                                        $('#tamkinPlayerApp .accessibility-icon-container').addClass('ltr');
                                    }
                                    tamkinPlayerApp.style.setProperty('--tp-accessibility3-top', '80%');
                                }
                            }
                        }
                        // handle detect lang 
                        const detectLang = data.data.features.find(feature => feature.name === 'acc-customize-language')?.features
                            .find(el => el.name === 'acc-customize-language-list-of-languages');
                        if (detectLang.active == 1 && detectLang.value == "auto detect language") {
                            fetch('https://api.tamkin.app/v1/api/Widget/LanguageDetector', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                    text: document.title
                                })
                            })
                                .then(response => {
                                    if (!response.ok) {
                                        throw new Error(`HTTP error! Status: ${response.status}`);
                                    }
                                    return response.json();
                                })
                                .then(data => {
                                    if (data.data) {
                                        // handle auto detect 
                                        this.state.defaultLang = this.state.languages.find(el => el.language_code == localStorage.getItem('lang'))
                                        this.autoDetectLang(this.state.defaultLang)
                                        this.update()
                                    }
                                })
                                .catch(error => {
                                    console.error('Fetch error:', error);
                                });
                        }
                        else if (detectLang.active == 1 && detectLang.value !== 'auto detect language') {
                            // handle auto detect 
                            this.state.defaultLang = this.state.languages.find(el => el.language_code == detectLang.value)
                            this.autoDetectLang(this.state.defaultLang);
                            this.update();
                        }

                        const checkHighlight = data.data.features.find(feature => feature.name === 'acc-customize-language')?.features
                            .find(el => el.name === 'acc-customize-language-show-language-selector-on-the-widget');

                        if (checkHighlight?.active == 1 && checkHighlight?.value == 1) {
                            this.update({ makeHighlight: true })
                        } else {
                            this.update({ makeHighlight: false })
                        }

                    })
                    .catch(error => { });

            },
            autoDetectLang(lang) {
                var selectBox = document.querySelector("#google_translate_element select.goog-te-combo");
                if (selectBox && selectBox.value != lang.language_code) {
                    // trigger google change translate the website
                    selectBox.value = lang.language_code;

                    localStorage.setItem("lang", lang.language_code)
                    var event = new Event('change', { 'bubbles': true, 'cancelable': true });
                    selectBox.dispatchEvent(event);
                    setTimeout(() => {
                        this.activateLanguage(lang);
                    }, 1500);
                }
            },

            onMounted(props, state) {
                document.querySelectorAll("body > *:not(#tamkinPlayerApp):not(tamkin-player-sdk):not(#tamkinPlayerApp *):not(tamkin-player-sdk *)")
                    .forEach(element => {
                        element.addEventListener("click", () => {
                            const sidebar = document.getElementById("tp-accessibility3-sidebar");
                            if (sidebar && sidebar.classList.contains("active")) {
                                this.closeAcc(); // Execute the closeAcc method
                            }
                        });

                    });



                // fix: when opening the last accordion collapse in the sidebar it doesn't trigger scrolling to see its contents
                $('#tamkinPlayerApp #tp-accessibility3-sidebar .collapse-position-btn').on('click', function (e) {
                    let $sidebarBody = $('#tamkinPlayerApp #tp-accessibility3-sidebar .tp-accessibility3-sidebar-body');
                    // $sidebarBody.scrollTop($sidebarBody.prop("scrollHeight"));
                    $sidebarBody.animate({
                        scrollTop: $sidebarBody.prop("scrollHeight")
                    }, 500);
                });

                // set sidebar saved position
                this.setSidebarPositionDom(localStorage.getItem("tpSidebarPosition") ? localStorage.getItem("tpSidebarPosition") : this.state.sidebarPosition);

            },
            activateLanguage(active_lang) {
                // check lang_name is string or object
                let activeLanguage;
                if (typeof active_lang == 'string') {
                    activeLanguage = this.state.languages.find(l => l.language_code == active_lang);
                } else {
                    activeLanguage = active_lang
                }

                // set lang as active and deactivate others
                this.state.filteredLanguages.forEach(function (l) { if (l.active) { l.active = false } });
                activeLanguage.active = true;
                this.update()

                // clone the active language html to inside #g-lang-active (accordion btn)
                let activeLangHTML = $("#g-lang-" + activeLanguage.language_code + " .g-lang-inside").html()
                $("#g-lang-active .g-lang-inside").html(activeLangHTML);

                // close the language accordion
                if ($("#collapseLang").hasClass('show')) {
                    $("#collapseLang").removeClass('show')
                    $("#g-lang-active").attr('aria-expanded', 'false').removeClass('collapsed');
                }

                // rtl sidebar
                setTimeout(() => {
                    if ($('html').hasClass('translated-rtl')) {
                        $('#tamkinPlayerApp #tp-accessibility3-sidebar').addClass('rtl');
                    } else {
                        $('#tamkinPlayerApp #tp-accessibility3-sidebar').removeClass('rtl');
                    }
                }, 500);
            },
            onUpdated(props, state) {

            },
            searchLanguages(e) {
                let search = $(e.target).val();
                this.update({ filteredLanguages: this.state.languages.filter(lang => lang.language_name.toLowerCase().includes(search.toLowerCase())) });
            },
            removeSearchLanguageTrim(e) {
                $("#tp-search").val('')
                this.update({ filteredLanguages: this.state.languages })
            },
            changeLanguage(e, lang) {
                var selectBox = document.querySelector("#google_translate_element select.goog-te-combo");
                if (selectBox && selectBox.value != lang.language_code) {
                    // trigger google change translate the website
                    selectBox.value = lang.language_code;
                    var event = new Event('change', { 'bubbles': true, 'cancelable': true });
                    selectBox.dispatchEvent(event);

                    if (this.state.makeHighlight) {
                        $('body *:not(div.notranslate *)').each(function () {
                            if ($(this).children().length === 0 && $(this).text().trim() !== '') {
                                $(this).addClass('highlight');
                            }
                        });
                    }
                    // sometimes this doesn't work, so i try to trigger it again depending on html tag has eigher 'translated-ltr' or 'translated-rtl'
                    setTimeout(() => {
                        $('.highlight').removeClass('highlight');
                        if (!$('html').hasClass('translated-rtl') && !$('html').hasClass('translated-ltr')) {
                            $(e.target).trigger('click');
                        }

                        this.activateLanguage(lang);
                    }, 1500);

                    localStorage.setItem("lang", lang.language_code)

                }
            },
            loadFile(path) {
                return 'https://tamkin.app' + path;
            },

            sidebarPosition(e) {
                if ($(e.target).attr('value') == 'left') {
                    this.update({ sidebarPosition: 'left' })
                    localStorage.setItem('tpSidebarPosition', 'left');
                    this.setSidebarPositionDom('left');
                } else if ($(e.target).attr('value') == 'right') {
                    this.update({ sidebarPosition: 'right' })
                    localStorage.setItem('tpSidebarPosition', 'right');
                    this.setSidebarPositionDom('right');
                } else if ($(e.target).attr('value') == 'hide') {
                    this.update({ sidebarPosition: 'hide' })
                    $("#tamkinPlayerApp .tp-accessibility-sidebar-body").animate({
                        scrollTop: "+=200px"
                    }, 100);
                }
            },
            setSidebarPositionDom(position = 'right') {
                if (position === 'left') {
                    $('#tamkinPlayerApp #tp-accessibility3-sidebar').addClass('ltr');
                    $('#tamkinPlayerApp .accessibility-icon-container').addClass('ltr');
                } else {
                    $('#tamkinPlayerApp #tp-accessibility3-sidebar').removeClass('ltr');
                    $('#tamkinPlayerApp .accessibility-icon-container').removeClass('ltr');
                }
            },
            sidebarHide(e) {
                let duration = $(e.target).val();
                if (duration === 'always') {
                    localStorage.setItem('tpAccHideUntil', 'forever');
                } else {
                    const now = new Date();
                    let tpAccHideUntil = new Date();
                    switch (duration) {
                        case 'session':
                            tpAccHideUntil.setTime(now.getTime() + 30 * 60 * 1000); // 3600000 ms = 1 hour
                            window.location.reload();
                            break;
                        case 'day':
                            tpAccHideUntil.setDate(now.getDate() + 1);
                            break;
                        case 'week':
                            tpAccHideUntil.setDate(now.getDate() + 7);
                            break;
                        case 'month':
                            tpAccHideUntil.setMonth(now.getMonth() + 1);
                            break;
                    }
                    localStorage.setItem('tpAccHideUntil', tpAccHideUntil.toString());
                }

                this.update();

                $('accessibility > div').fadeOut().delay(2000).remove();
            },
            sidebarIsHidden() {
                const tpAccHideUntil = localStorage.getItem('tpAccHideUntil');
                if (!tpAccHideUntil) {
                    return false;
                } else {
                    if (new Date() < new Date(tpAccHideUntil)) {
                        return true;
                    } else {
                        localStorage.removeItem('tpAccHideUntil');
                        return false;
                    }
                }
            },
            sidebarIsLeft() {
                return this.state.sidebarPosition === 'left' || localStorage.getItem('tpSidebarPosition') === 'left';
            },
            HidePostion(e) {
                $("#tp-accordion-hide").toggle();
                $("#tamkinPlayerApp .tp-accessibility-sidebar-body").animate({
                    scrollTop: "+=200px"
                }, 100);
            }

        }
    </script>

    <style>
    </style>
</accessibility-sidebar>