<dictionary-modal>
    <div>
        <div class="modal fade" id="DictionaryModal" tabindex="-1" aria-labelledby="DictionaryModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered rounded-2">
                <div class="modal-content" style="position: relative;">
                    <div class="modal-header bg-white tp-modal-header" style="border-radius:12px">
                        <button class="modal_close_dic" type="button" onclick="{closeModal}" aria-label="Close">
                            <svg class="nonehovered" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 6L6 18" stroke="#FFF" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path d="M6 6L18 18" stroke="#FFF" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </button>
                    </div>

                    <div class="modal-body bg-white"
                        style="border-radius:12px;max-height: 90vh;overflow-y: auto;scrollbar-width: thin;">
                        <div style="position: relative;">
                            <input type="text" oninput="{sendDataDictionary}" class="form-control h-50px input-border rounded-4 
                             ui-keyboard-input ui-widget-content ui-corner-all" id="dictionaryInput"
                                placeholder="Search" aria-haspopup="true" role="textbox">
                            <img class="searchIcon" src="{window.tamkin_src_base+'/images/searchicon.svg'}"
                                alt="search">
                            <svg onclick="{clearInputSearch}" class="clearInput" width="18" height="18"
                                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 6L6 18" stroke="#FFF" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path d="M6 6L18 18" stroke="#FFF" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                        </div>


                        <div class="loading" id="loading">
                            <div class="spinner"></div>
                            <h3>Searching </h3>
                            <p>Please wait while we retrieve the definition for you...</p>
                        </div>


                        <div id="dictionarybody" class="mt-2">

                        </div>


                        <div class="loading" id="no_data">
                            <img class="nodata" src="{window.tamkin_src_base+'/images/noData.svg'}" alt="noData">
                            <h3>No Results Found </h3>
                            <p>Please check your spelling and try again.</p>
                        </div>

                        <div id="dictionfooter"
                            class="dictionfooter mt-2 d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center" style="gap: 20px;">
                                <button class="readBtn">
                                    <img src="{window.tamkin_src_base+'/images/read.svg'}">
                                    <span>Read</span>
                                </button>
                                <button class="spillBtn" onclick="{spellWord}">
                                    <img src="{window.tamkin_src_base+'/images/innerWave.svg'}">
                                    <span>Spell</span>
                                </button>
                            </div>

                            <div class="pagination">
                                <button id="prev"><img src="{window.tamkin_src_base+'/images/left_arrow.svg'}"></button>
                                <span id="page-numbers"></span>
                                <button id="next"><img
                                        src="{window.tamkin_src_base+'/images/right_arrow.svg'}"></button>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div>
    </div>

    <script>
        // this puts svg images inside file3D/images. see webpack.config.js 
        const importAllImages = (requireContext) => requireContext.keys().map(requireContext);
        const images = importAllImages(require.context('/src/player_files/accessibility/img', false, /\.(png|jpe?g|svg)$/));

        import "/src/player_files/accessibility/bootstrap.min.js";


        export default {
            components: {
            },
            state: {
                loader: false
            },
            speak(text, rate) {
                document.querySelectorAll("*").forEach((el) => {
                    el.classList.remove("highlight")
                })
                window.speechSynthesis.cancel();
                const validRate = isFinite(parseFloat(rate)) ? parseFloat(rate) : 1.0;
                const speech = new SpeechSynthesisUtterance(text);
                speech.lang = `${localStorage.getItem("lang")}-EG`;
                speech.rate = validRate;


                window.speechSynthesis.speak(speech);

                speech.onend = () => {
                    window.speechSynthesis.cancel();
                };

            },
            closeModal() {
                window.DictionaryModalBs.hide();
                window.speechSynthesis.cancel();
            },
            sendDataDictionary() {
                $('#DictionaryModal #dictionarybody').empty();
                $('#DictionaryModal #dictionarybody').hide();
                $('#DictionaryModal #no_data').hide();
                $('#DictionaryModal #loading').show();

                window.speechSynthesis.cancel();
                let selectedText = document.getElementById("dictionaryInput").value;

                fetch('https://api.tamkin.app/v1/api/Widget/LanguageDictionary', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        lang: localStorage.getItem("lang"),
                        text: selectedText
                    })
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(data => {
                        if (data?.data) {
                            $('#DictionaryModal #dictionarybody').show();
                            $('#DictionaryModal #loading').hide();
                            $('#DictionaryModal #no_data').hide();

                            const readBtn = document.querySelector(".readBtn");

                            if (readBtn && data.data) {
                                readBtn.addEventListener('click', () => {
                                    this.speak(data.data, 1.0);
                                });
                            }

                            const text = data.data;
                            const pageSize = 320;
                            let currentPage = 1;

                            const pages = Math.ceil(text.length / pageSize);
                            const textContainer = document.getElementById('dictionarybody');
                            const pageNumbers = document.getElementById('page-numbers');
                            const prevButton = document.getElementById('prev');
                            const nextButton = document.getElementById('next');

                            if (!textContainer || !pageNumbers || !prevButton || !nextButton) {
                                return;
                            }

                            function createPage(pageNumber) {
                                const start = (pageNumber - 1) * pageSize;
                                const end = Math.min(start + pageSize, text.length);
                                return text.slice(start, end);
                            }

                            function showPage(pageNumber) {
                                if (pageNumber < 1 || pageNumber > pages) return;
                                textContainer.textContent = createPage(pageNumber);
                                currentPage = pageNumber;
                                updatePagination();
                            }

                            function updatePagination() {
                                pageNumbers.innerHTML = '';

                                const maxVisiblePages = 5;
                                const startPage = Math.max(1, currentPage - 2);
                                const endPage = Math.min(pages, currentPage + 2);

                                if (startPage > 1) {
                                    addPageButton(1);
                                    if (startPage > 2) {
                                        addEllipsis();
                                    }
                                }

                                for (let i = startPage; i <= endPage; i++) {
                                    addPageButton(i);
                                }

                                if (endPage < pages) {
                                    if (endPage < pages - 1) {
                                        addEllipsis();
                                    }
                                    addPageButton(pages);
                                }

                                prevButton.disabled = currentPage === 1;
                                nextButton.disabled = currentPage === pages;
                            }

                            function addPageButton(pageNumber) {
                                const button = document.createElement('button');
                                button.textContent = pageNumber;
                                button.onclick = () => showPage(pageNumber);
                                if (pageNumber === currentPage) {
                                    button.classList.add("active")
                                }
                                pageNumbers.appendChild(button);
                            }

                            function addEllipsis() {
                                const ellipsis = document.createElement('span');
                                ellipsis.textContent = '...';
                                pageNumbers.appendChild(ellipsis);
                            }

                            prevButton.onclick = () => showPage(currentPage - 1);
                            nextButton.onclick = () => showPage(currentPage + 1);

                            showPage(currentPage);

                            this.update();

                        } else {
                            $('#DictionaryModal #dictionarybody').hide();
                            $('#DictionaryModal #loading').hide();
                            $('#DictionaryModal #no_data').show();
                        }

                    })
                    .catch(error => {
                        console.error('Fetch error:', error);
                    });

            },

            spellWord() {
                const rate = 1.0
                const word = document.getElementById("dictionaryInput").value
                window.speechSynthesis.cancel();
                const self = this;
                const synth = window.speechSynthesis;

                const letters = word.split('');

                function speakLetter(index) {
                    if (index >= letters.length) return;
                    const utterance = new SpeechSynthesisUtterance(letters[index]);
                    utterance.rate = rate;
                    utterance.lang = `${localStorage.getItem("lang")}-EG`
                    synth.speak(utterance);
                    utterance.onend = () => speakLetter(index + 1);
                }
                speakLetter(0);
            },


            clearInputSearch() {
                document.getElementById("dictionaryInput").value = "";
                this.update();
                this.sendDataDictionary();
            },
            onMounted(props, state) {

            },
            onUpdated(props, state) {

            },

        }
    </script>

    <style>
        /*
        some styles fot accessibility are in pricipalApp.riot because not working here any styles
        */

        .clearInput {
            position: absolute;
            top: 11px;
            right: 10px;
            cursor: pointer;

        }

        .clearInput path {
            stroke: #585B5B;
        }

        #DictionaryModal .modal_close_dic {
            position: absolute;
            top: -23px;
            right: -23px;
            height: 35px;
            background-color: #FFF;
            z-index: 3000;
            width: 35px;
            box-shadow: 0px 4px 24px 18px #51459F14;
            border-radius: 50%;
        }

        #DictionaryModal .modal_close_dic svg path {
            stroke: #585B5B;
        }

        #DictionaryModal .modal_close_dic:hover {
            background-color: var(--tp-primary);
        }

        #DictionaryModal .modal_close_dic:hover svg path {
            stroke: #FFF;
        }

        .section-title {
            color: #d9d6db;
            margin-top: 0;
            margin-bottom: 48px;
            font-size: 42px;
            font-weight: 700;
            line-height: 48px;
            display: inline-block;
        }

        .dictionfooter .readBtn,
        .dictionfooter .spillBtn {
            box-shadow: 0px 0px 6.5px 0px rgba(var(--tp-primary), 0.5);
            border-radius: 10px !important;
            font-size: 13px;
            font-weight: 500;
            line-height: 19.5px;
            letter-spacing: 0.30000001192092896px;
            padding: 5px 10px;
            color: #000;
            display: flex;
            justify-content: space-between;
            gap: 5px;
            align-items: center;
        }

        .dictionfooter .readBtn img,
        .dictionfooter .spillBtn img {
            width: 30px;
            height: 20px;
        }

        #DictionaryModal .difenition .wordDif {
            font-size: 14;
            font-weight: 700;
            color: #000;
        }

        #dictionaryInput {
            padding: 5px 40px !important;
            font-size: 13px !important;
            font-weight: 500 !important;
            line-height: 19.5px;
            letter-spacing: 0.30000001192092896px;
            color: #6D6D6D !important;
            border: 1px solid var(--tp-primary) !important;
            outline: 0;
            box-shadow: none !important;
            height: 40px !important;
        }

        .searchIcon {
            position: absolute;
            top: 15px;
            left: 15px;
        }

        .spinner {
            width: 33px;
            height: 33px;
            border: 3.91px solid transparent;
            border-top-color: var(--tp-primary);
            border-right-color: var(--tp-primary);
            border-radius: 50%;
            animation: spin 1s linear infinite;
            background-origin: border-box;
            background-clip: border-box;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        .loading {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;
            gap: 8px;
        }

        .loading h3 {
            font-size: 13px;
            font-weight: 600;
            line-height: 19.5px;
            letter-spacing: 0.30000001192092896px;
            text-align: center;
        }

        .loading p {
            font-size: 10px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0.30000001192092896px;
            text-align: center;
        }


        .pagination {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .pagination button,
        .pagination span {
            margin: 0 5px;
            padding: 4px 10px;
            border: none;
            background-color: #fff;
            color: #585B5B !important;
            cursor: pointer;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            text-align: center;
            transition: background-color 0.3s ease;
        }



        .pagination button:disabled {
            cursor: not-allowed;
        }

        .pagination button.active {
            font-weight: bold;
            /* background: linear-gradient(180deg, #2DADA3 0%, #71DAD2 100%) !important; */
            background-color: var(--tp-primary);
            color: white !important;
            border-radius: 4px !important;
        }


        .pagination span {
            cursor: default;
            background-color: transparent;
            color: #666;
        }

        .pagination button:hover {
            color: var(--tp-primary);
        }

        .pagination button#prev,
        .pagination button#next {
            font-weight: bold;
            color: white;
            border-radius: 4px;
            padding: 8px 12px;
        }

        .pagination button#prev:hover,
        .pagination button#next:hover {
            color: var(--tp-primary);
        }

        .pagination button#prev:disabled,
        .pagination button#next:disabled {
            color: #666;
            cursor: not-allowed;
        }
    </style>

</dictionary-modal>