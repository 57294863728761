<voice-navigation>
    <div id="voice-nav-modal">
        <div class="voice-nav-header d-flex align-items-center justify-content-between">
            <div class=" d-flex align-items-center justify-content-between" style="gap: 25px;">
                <button id="openlisten" onclick="{initRec}">
                    <img width="40" height="40" src="{window.tamkin_src_base+'/images/mic.svg'}" alt="Close">
                </button>
                <p id="titleModal" class="titleModal">
                    <!-- Please allow access permission to your microphone -->
                    <span>{state.listening ? 'Tamkin is listening' : 'Tamkin is paused'}</span>

                </p>
            </div>
            <p id="lisetning_text" style="display: none;">listening</p>


            <div class=" d-flex justify-content-between align-items-center" style="gap: 15px;">
                <p if="{state.text === 'Tamkin is paused' && state.paused == 1}" class="" id="pleasestart">Say
                    <bclass="">"Start"</b>
                        or click on microphone
                        to continue!
                </p>
                <img src="{window.tamkin_src_base+'/images/addvoice.svg'}" onclick="{updateShowDetails}"
                    if="{state.showDetails==false}" alt="add">
                <img src="{window.tamkin_src_base+'/images/minvoice.svg'}" onclick="{updateHideDetails}"
                    if="{state.showDetails==true}" alt="min">
                <img src="{window.tamkin_src_base+'/images/closebtn.svg'}" onclick="{closeVoiceNavigation}" alt="Close">
            </div>
        </div>
        <hr if="{state.showDetails==true}" style="border: 1px solid #A6A6A6;" />
        <div class="voice-nav-body" if="{state.showDetails==true}">
            <div each="{command in state.commands}" class="command-item" key="{command.title}">
                <div>
                    <span class="title-bold">{command.description}</span>

                </div>
                <div style="display: flex;gap: 10px;flex-wrap: wrap;margin: 10px 0px;">
                    <div class="command-item" each="{item in command.words_list}">{item.word}</div>
                </div>

            </div>
        </div>
    </div>


    <script>
        const importAllImages = (requireContext) => requireContext.keys().map(requireContext);
        const images = importAllImages(require.context('/src/player_files/accessibility/img', false, /\.(png|jpe?g|svg)$/));

        export default {
            state: {
                text: "",
                listening: false,
                showDetails: false,
                commands: [],
                recognition: new webkitSpeechRecognition(),
                getstart: 0,
                paused: 0
            },
            onMounted(state, props) {
                this.getCommands();
            },



            initRec() {
                this.state.text = 'Tamkin is listening';
                document.getElementById("titleModal").innerText = 'Tamkin is listening';

                this.state.listening = !this.state.listening;

                if (this.state.getstart == 0) {
                    this.state.recognition.start();
                }

                this.update();
                if (this.state.listening) {
                    this.state.getstart = 1;
                    this.showNumbers();

                    this.state.recognition.lang = `${localStorage.getItem("lang")}-EG`;
                    this.state.recognition.interimResults = true;

                    this.state.recognition.onresult = (event) => {
                        const transcript = event.results[event.results.length - 1][0].transcript;
                        this.state.text = transcript;
                    };

                    this.state.recognition.onerror = (event) => {
                        console.error("Error:", event.error);
                        if (event.error === 'no-speech') {
                            $("#openlisten").removeClass("active");
                            this.state.listening = false;
                            this.state.text = 'Tamkin is paused';
                            document.getElementById("titleModal").innerText = 'Tamkin is paused';
                            this.update();
                        }

                    };

                    this.state.recognition.onend = () => {
                        console.log("test", this.state.text);
                        console.log("listening", this.state.listening);

                        // if (this.state.listening) {
                        if (this.state.text) {
                            if (this.state.paused == 0) {
                                document.getElementById("titleModal").innerText = this.state.text;
                            }
                            this.handleCommand(this.state.text);
                        }
                        // }
                        this.state.recognition.start();
                    };

                    const textSpeech = $("#lisetning_text").text();
                    const speech = new SpeechSynthesisUtterance(textSpeech);
                    speech.lang = `${localStorage.getItem("lang")}-EG`;
                    speech.rate = 1.0;

                    window.speechSynthesis.speak(speech);

                    speech.onend = () => {
                        window.speechSynthesis.cancel();
                    };


                    $("#openlisten").addClass("active");
                } else {
                    this.stopVoiceNavigation();
                    // $("#openlisten").removeClass("active");
                    // this.state.text = 'Tamkin is paused';
                    // document.getElementById("titleModal").innerText = 'Tamkin is paused';
                }
            },



            // startListen() {
            //     this.state.showDetails = true
            //     var recordAudioButton = document.getElementById("openlisten");
            //     var mediaRecorder;
            //     var audioContext;
            //     var scriptProcessor;
            //     var autoStopAfter = 4000;
            //     var time;
            //     var isRecording = false;

            //     var run = function (stream) {
            //         audioContext = new (window.AudioContext || window.webkitAudioContext)();
            //         scriptProcessor = audioContext.createScriptProcessor(4096, 1, 1);
            //         var source = audioContext.createMediaStreamSource(stream);
            //         source.connect(scriptProcessor);
            //         scriptProcessor.connect(audioContext.destination);

            //         scriptProcessor.onaudioprocess = function (event) {
            //             var inputData = event.inputBuffer.getChannelData(0);
            //             var hasAudio = inputData.some(value => Math.abs(value) > 0.01); // Adjust threshold as needed

            //             // if (hasAudio && !isRecording) {
            //             //     startRec();
            //             //     time = setTimeout(stopRec, autoStopAfter);
            //             // }
            //         };

            //         mediaRecorder = new MediaRecorder(stream);
            //         mediaRecorder.ondataavailable = getAudio;
            //         recordAudioButton.addEventListener("click", recordHandler);
            //     };

            //     function recordHandler() {
            //         if (isRecording) {
            //             stopRec();
            //         } else {
            //             startRec();
            //             time = setTimeout(stopRec, autoStopAfter);
            //         }
            //     }

            //     function startRec() {
            //         const speech = new SpeechSynthesisUtterance('listening');
            //         speech.lang = `en-US`;
            //         speech.rate = 1.0;

            //         window.speechSynthesis.speak(speech);

            //         speech.onend = () => {
            //             window.speechSynthesis.cancel();
            //         };

            //         $("#openlisten").addClass("active");
            //         mediaRecorder.start();
            //         isRecording = true;
            //     }

            //     function stopRec() {
            //         mediaRecorder.stop();
            //         isRecording = false;
            //         $("#openlisten").removeClass("active");
            //     }

            //     function getAudio(event) {
            //         if (event.data.size > 0) {
            //             var reader = new FileReader();
            //             reader.onloadend = function () {
            //                 var base64data = reader.result;
            //                 sendToServer(base64data);
            //             };
            //             reader.readAsDataURL(event.data);
            //         }
            //     }

            //     function sendToServer(base64String) {
            //         fetch('https://api.tamkin.app/v1/api/Widget/Voice2Text', {
            //             method: 'POST',
            //             headers: {
            //                 'Content-Type': 'application/json',
            //             },
            //             body: JSON.stringify({ audio: base64String })
            //         })
            //             .then(response => response.json())
            //             .then(data => console.log(data))
            //             .catch(error => console.error('Error sending audio:', error));
            //     }

            //     const enableAudio = { audio: true };
            //     navigator.mediaDevices.getUserMedia(enableAudio).then(run).catch(error => console.error('Error accessing media devices.', error));
            // },


            handleCommand(command) {
                if (command === "help me" || command === "show commands" || command === "please help" ||
                    command === "available commands" || command === "list commands" || command === "help"
                ) {
                    this.state.showDetails = true;
                    this.update();
                } else if (command === "hide help" || command === "hide commands") {
                    this.state.showDetails = false;
                    this.update();
                }
                else if (command === "clear input" || command === "erase" ||
                    command === "delete" || command === "remove"
                ) {
                    document.querySelectorAll("input").forEach((el) => {
                        el.value = ""
                    })
                } else if (command === "refresh page" || command === "reload page") {
                    window.location.reload();
                } else if (command === "scroll to top" || command === "go to top") {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                } else if (command === "scroll to bottom" || command === "go to bottom") {
                    window.scrollTo({
                        top: document.body.scrollHeight,
                        behavior: 'smooth'
                    });
                }
                else if (command === "scroll down" || command === "down" ||
                    command === "page down" || command === "go down"
                ) {
                    window.scrollBy({
                        top: 150,
                        behavior: 'smooth'
                    });
                }
                else if (command === "scroll up" || command === "up" || command === "page up" ||
                    command === "go up"
                ) {
                    window.scrollBy({
                        top: -150,
                        behavior: 'smooth'
                    });
                } else if (command === 'stop voice navigation' || command === 'stop') {
                    this.stopVoiceNavigation();

                } else if (command === 'hide widget' || command === 'close widget') {
                    $("#tp-accessibility-sidebar").removeClass("active")
                } else if (command === 'move up' || command === 'up') {
                    window.scrollBy({
                        top: -150,
                        behavior: 'smooth'
                    });
                } else if (command === 'move down' || command === 'down') {
                    window.scrollBy({
                        top: 150,
                        behavior: 'smooth'
                    });
                } else if (command === 'move left' || command === 'left') {
                    window.scrollBy({
                        left: -150,
                        behavior: 'smooth'
                    });
                } else if (command === 'move right' || command === 'right') {
                    window.scrollBy({
                        left: 150,
                        behavior: 'smooth'
                    });
                } else if (command === 'exit') {
                    this.closeVoiceNavigation()
                } else if (command === 'show numbers' || command === 'display numbers') {
                    this.showNumbers()
                } else if (command.toLowerCase() === 'start') {
                    this.startVoiceNavigation();
                } else if (command === 'hide numbers') {
                    this.hideNumnbers()
                } else if (command.toLowerCase().startsWith('click') || command.toLowerCase().startsWith('go')
                    || command.toLowerCase().startsWith('press') || command.toLowerCase().startsWith('open')
                    && /\d/.test(command)) {
                    const links = document.querySelectorAll('a:not(#tamkinPlayerApp *)');
                    links.forEach((link, index) => {
                        link.setAttribute('data-index', index + 1);
                    });

                    const number = parseInt(command.split(" ")[1], 10);

                    if (!isNaN(number)) {
                        const element = document.querySelector(`[data-index="${number}"]`);

                        if (element) {
                            element.click();
                        } else {
                            console.log(`No element found with index ${number}`);
                        }
                    }
                }
                else if (command.toLowerCase().startsWith('select') || command.toLowerCase().startsWith('focus') ||
                    command.toLowerCase().startsWith('show') && /\d/.test(command)) {
                    const links = document.querySelectorAll('a:not(#tamkinPlayerApp *)');
                    links.forEach((link, index) => {
                        link.setAttribute('data-index', index + 1);
                    });

                    const number = parseInt(command.split(" ")[1], 10);

                    if (!isNaN(number)) {
                        const element = document.querySelector(`[data-index="${number}"]`);

                        if (element) {
                            element.focus();
                        } else {
                            console.log(`No element found with index ${number}`);
                        }
                    }
                } else if (/\d/.test(command)) {
                    const links = document.querySelectorAll('a:not(#tamkinPlayerApp *)');
                    links.forEach((link, index) => {
                        link.setAttribute('data-index', index + 1);
                    });

                    const number = parseInt(command, 10);

                    if (!isNaN(number)) {
                        const element = document.querySelector(`[data-index="${number}"]`);

                        if (element) {
                            element.click();
                            element.focus();
                        } else {
                            console.log(`No element found with index ${number}`);
                        }
                    }
                } else if (command === 'tab' || command === 'next') {
                    const focusableElements = 'a:not(#tamkinPlayerApp *), button, input, select, textarea, [tabindex]:not([tabindex="-1"])';
                    const elements = Array.from(document.querySelectorAll(focusableElements));
                    const currentIndex = elements.indexOf(document.activeElement);

                    let nextIndex = currentIndex + 1;
                    if (nextIndex >= elements.length) {
                        nextIndex = 0;
                    }

                    elements[nextIndex].focus();
                } else if (command === 'tab back' || command === 'back' || command === 'previous') {
                    const focusableElements = 'a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])';
                    const elements = Array.from(document.querySelectorAll(focusableElements));
                    const currentIndex = elements.indexOf(document.activeElement);

                    let previousIndex = currentIndex - 1;
                    if (previousIndex < 0) {
                        previousIndex = elements.length - 1;
                    }

                    elements[previousIndex].focus();
                } else if (command === 'enter') {
                    const activeElement = document.activeElement;
                    if (activeElement) {
                        const enterEvent = new KeyboardEvent('keydown', {
                            key: 'Enter',
                            keyCode: 13,
                            code: 'Enter',
                            which: 13,
                            bubbles: true,
                        });
                        activeElement.dispatchEvent(enterEvent);
                        const clickEvent = new MouseEvent('click', {
                            bubbles: true,
                            cancelable: true,
                            view: window
                        });

                        activeElement.dispatchEvent(clickEvent);
                    }
                } else {
                    return;
                }
            },
            closeVoiceNavigation() {
                $("#voice-nav-modal").removeClass("active");
                this.state.recognition.stop();
                this.hideNumnbers();
                this.state.listening = false;
                this.update();
                $("#openlisten").removeClass("active");
                $(".tp-accessibility4").css("display", 'block')

            },
            stopVoiceNavigation() {
                this.state.listening = false;
                this.state.paused = 1;
                this.state.text = 'Tamkin is paused';
                document.getElementById("titleModal").innerText = 'Tamkin is paused';

                $("#openlisten").removeClass("active");
                this.update();
            },
            startVoiceNavigation() {
                this.state.listening = true;
                this.state.paused = 0;
                this.state.text = 'Tamkin is listening';
                document.getElementById("titleModal").innerText = 'Tamkin is listening';

                $("#openlisten").addClass("active");
                this.update();
            },
            updateShowDetails(payload) {
                this.state.showDetails = true;
                this.update();
            },
            updateHideDetails(payload) {
                this.state.showDetails = false;
                this.update();
            },

            showNumbers() {
                $('a:not(#tamkinPlayerApp *)').each(function (index) {
                    $(this).css('position', 'relative')
                    $(this).css('display', 'inline-block')

                    $(this).append(`<span class="num_tooltip">${index + 1}</span>`)
                    $('<style>')
                        .prop('type', 'text/css')
                        .html(`
                            .num_tooltip {
                                visibility: visible;
                                
                                background-color: black;
                                color: #fff;
                                text-align: center;
                                border-radius: 5px;
                                padding: 5px 10px;
                                position: absolute;
                                z-index: 1;
                                bottom: -100%; 
                                left: 60%;
                                margin-left: -30px; 
                                font-size: 12px;
                                font-weight: 500;
                                opacity: 1;
                                transition: opacity 0.3s;
                            }
                                .num_tooltip::after {
                                    content: "";
                                    position: absolute;
                                    bottom: 100%; 
                                    left: 50%;
                                    margin-left: -5px;
                                    border-width: 5px;
                                    border-style: solid;
                                    border-color: transparent transparent black transparent;
                                }
                            
                        
                        `)
                        .appendTo('head');
                });
            },

            hideNumnbers() {
                $('a:not(#tamkinPlayerApp *)').each(function () {
                    $(this).find('.num_tooltip').remove();
                });
            },
            getCommands() {
                fetch('https://api.tamkin.app/v1/api/Widget/VoiceCommands', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({

                    })
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! Status: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(data => {
                        if (data?.data) {
                            this.state.commands = data.data
                        }

                    })
                    .catch(error => {
                        console.error('Fetch error:', error);
                    });

            },
        }
    </script>
    <style>
        #voice-nav-modal {
            position: fixed;
            bottom: -100%;
            transition: all 0.5s;
            left: 50%;
            transform: translateX(-50%);
            z-index: 3000;
            padding: 14px;
            background-color: #F2F2F2;
            box-shadow: 0px 4px 8px 0px #00000014;
            border-radius: 8px;
            max-width: 792px;
            width: 80%;
        }

        #voice-nav-modal.active {
            bottom: 35px;
        }

        #voice-nav-modal img {
            cursor: pointer;
        }

        .voice-nav-header .titleModal {
            font-size: 16px;
            font-weight: 600;
            color: #1E1E1E;
            margin: 0px !important;
            transition: all 0.5s;
            animation: typing 3.5s steps(40, end);
        }

        @keyframes typing {
            from {
                width: 0
            }

            to {
                width: 100%
            }
        }

        .voice-nav-body {
            transition: all 0.5s;
            max-height: 300px;
            overflow-y: auto;
            scrollbar-width: thin;
        }

        .command-item .title-bold {
            font-weight: 600;
            font-size: 16px;
            color: #000;
        }

        .command-item .title-thin {
            font-weight: 600;
            font-size: 16px;
            color: #A6A6A6;
        }

        .command-item .command-item {
            padding: 8px 12px;
            border: 2px solid var(--tp-primary);
            font-weight: 600;
            font-size: 14px;
            color: var(--tp-primary);
            border-radius: 8px;
        }

        @keyframes shadowWaveAnimation {
            0% {
                box-shadow: 0 0 0 rgba(53, 192, 180, 0);
            }

            50% {
                box-shadow: 0 0 10px 7px rgba(53, 192, 180, 0.8);
            }

            100% {
                box-shadow: 0 0 0 rgba(53, 192, 180, 0);
            }
        }

        #openlisten {
            border-radius: 50%;
            width: 50px;
            height: 50px;
            position: relative;
        }

        #openlisten.active {
            animation: shadowWaveAnimation 2s ease infinite;
            animation-direction: normal;
            animation-fill-mode: none;
            animation-play-state: running;
        }

        @keyframes pulseAnimation {
            0% {
                transform: scale(1);
                opacity: 1;
            }

            50% {
                transform: scale(1.25);
                opacity: 0.5;
            }

            100% {
                transform: scale(1.5);
                opacity: 0;
            }
        }

        #openlisten.active:before {
            content: "";
            animation: pulseAnimation 1.05s ease-out 1s infinite;
            border-radius: 50%;
            position: absolute;
            z-index: -1;
            border: 1px solid var(--tp-primary);
            height: 50px;
            width: 50px;
            left: 0%;
            top: 0%;

        }

        #pleasestart {
            color: #333;
            font-size: 13px;
            font-weight: 500;
            margin-top: 15px;
        }
    </style>
</voice-navigation>