<acc-translation>
    <!-- TRANSLATE -->
    <div class="modal fade" id="translateModal" tabindex="-1" aria-labelledby="translateModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="pt-3" id="translateModalLabel">
                        <img class="modal-title" src="{window.tamkin_src_base+'/images/online-video.svg'}">
                    </div>
                    <button type="button" data-bs-dismiss="modal" aria-label="Close">
                        <img src="{window.tamkin_src_base+'/images/icon-close-modal.svg'}" alt="Close">
                    </button>
                </div>
                <p class=" font-18 font-w600">Now you can translate any video to sign language</p>
                <div class="modal-body" id="mediaTabs">
                    <ul class="nav nav-pills my-2" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link rounded-4 active p-0" data-bs-toggle="pill" href="#link">
                                <div class="rounded-4 p-2 d-flex align-items-center">
                                    <div class="rounded-circle tp-icon sm">
                                        <img src="{window.tamkin_src_base+'/images/video-link.svg'}" alt="">
                                    </div>
                                    <span class="ps-2 font-13 font-w400 mb-0 align-content-center">Video or audio
                                        link</span>
                                </div>
                            </a>
                        </li>
                        <li class="nav-item mx-3">
                            <a class="nav-link rounded-4 p-0" data-bs-toggle="pill" href="#upload">
                                <div class="rounded-4 p-2 d-flex align-items-center">
                                    <div class="rounded-circle tp-icon sm">
                                        <img src="{window.tamkin_src_base+'/images/upload-content.svg'}" alt="">
                                    </div>
                                    <span class="ps-2 font-13 font-w400 mb-0 align-content-center">Upload video or
                                        audio</span>
                                </div>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link rounded-4 p-0" data-bs-toggle="pill" href="#live">
                                <div class="rounded-4 p-2 d-flex align-items-center">
                                    <div class="rounded-circle tp-icon sm">
                                        <img src="{window.tamkin_src_base+'/images/live-streaming.svg'}" alt="">
                                    </div>
                                    <span class="ps-2 font-13 font-w400 mb-0 align-content-center">Live Video
                                        Link</span>
                                </div>
                            </a>
                        </li>
                    </ul>

                    <!-- Tab panes -->
                    <div class="tab-content">
                        <div id="link" class="tab-pane active">
                            <form class="link-translate-form" onsubmit={ handleLinkSubmit }>
                                <div class="p-4 rounded-4 media-tab">
                                    <input onkeyup={ updateVideoLink } value={ state.currentVideoLink } type="url"
                                        class="form-control h-50px input-border px-4 rounded-4 me-4" id="linkInput"
                                        placeholder="video link">
                                    <button disabled type="submit"
                                        class="btn btn-primary btn-translate tp-bg-primary px-4 border-0 h-50px rounded-4">Translate</button>
                                </div>
                            </form>
                        </div>
                        <div id="upload" class="tab-pane fade">
                            <div id="uploadContainer">
                                <div class="p-4 rounded-4 media-tab" id="mediaUpload">
                                    <img style="cursor: pointer!important;" class="img-fluid d-block mx-auto mb-2"
                                        src="{window.tamkin_src_base+'/images/dz-upload.svg'}" alt="Upload">
                                    <p class="font-14 font-w400 mb-0 text-center">
                                        Click to upload or drop media here
                                    </p>
                                    <!-- alert -->
                                    <div id="upload-error" class="alert alert-danger mt-3" role="alert"
                                        style="display: none;"></div>
                                    <!-- input -->
                                    <input type="file" accept="audio/*,video/*" id="mediaUploadInput" multiple
                                        style="display: none;" />
                                </div>
                                <div class="d-flex justify-content-between my-3">
                                    <p class="font-12 font-w400">Allowed files: Video & Audio</p>
                                    <p class="font-12 font-w400 ">Max Size: <span id="maxSizeMB"></span>MB</p>
                                </div>
                            </div>
                            <!-- progress -->
                            <div id="filePreview" class="rounded-4 px-4 py-3"
                                style="display:none; background-color: #D9D9D9;">
                                <button class="btn-close"></button>
                                <div class="d-flex">
                                    <img src="{window.tamkin_src_base+'/images/mp4-icon.svg'}" alt="">
                                    <div class="ms-2">
                                        <p class="font-12 font-w400 mt-3 mb-2">File Name: <span id="fileName"></span>
                                        </p>
                                        <p class="font-12 font-w400 ">File Size: <span id="fileSize"></span></p>
                                    </div>
                                    <!-- if completed -->
                                    <img class="ms-auto me-4 img-upload-success" style="display: none;width: 25px;"
                                        src="{window.tamkin_src_base+'/images/upload-success.svg'}"
                                        alt="Upload Complete">
                                </div>
                                <div id="upload-progress-container">
                                    <div style="height: 10px;" class="progress w-100">
                                        <div id="upload-progress"
                                            class="progress-bar progress-bar-striped tp-bg-primary progress-bar-animated">
                                        </div>
                                    </div>
                                    <span class="font-14 ms-3">0%</span>
                                </div>
                            </div>
                            <div class="mt-3">
                                <button disabled
                                    class="w-100 btn btn-primary btn-translate btn-translate-upload tp-bg-primary px-4 border-0 h-50px rounded-4">Translate</button>
                            </div>
                        </div>
                        <div id="live" class="tab-pane fade">
                            <div class="p-4 rounded-4 media-tab">
                                <input type="url" class="form-control h-50px input-border px-4 rounded-4 me-4"
                                    id="liveInput" placeholder="live video">
                                <button disabled
                                    class="btn btn-primary tp-bg-primary btn-translate px-4 border-0 h-50px rounded-4">Translate</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer footer-credits">
                    <a href="https://tamkin.app" target="_blank">
                        <span>Developed by Tamkin</span>
                        <img class="ms-2" src="{window.tamkin_src_base+'/images/link-external.svg'}" alt="Tamkin">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <!-- modal translation video -->
    <div class="modal fade translation-modal trans-video" id="translationModal" tabindex="-1"
        aria-labelledby="translationModalModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="pt-3" id="translationModalModalLabel">
                        <p class=" font-18 font-w600">Video & Audio Translation in ASL</p>
                    </div>
                    <button style="display: none;"
                        class="font-14 btn btn-primary btn-translate-asl asl-video tp-bg-primary px-4 border-0 h-40px rounded-4 ms-auto me-4">
                        Open ASL
                        <img class="mx-1" style="height: 21px;" src="{window.tamkin_src_base+'/images/asl.svg'}" alt="">
                    </button>
                    <button type="button" data-bs-dismiss="modal" aria-label="Close">
                        <img src="{window.tamkin_src_base+'/images/icon-close-modal.svg'}" alt="Close">
                    </button>
                </div>
                <div style="height: 415px;" class="modal-body px-4 pb-4 pt-0">
                    <div style="display: block;" class="translationLoaderContainer rounded-4 pb-4 h-100">
                        <div style="height: calc(100% - 50px);"
                            class="translationModalLoader rounded-4 d-flex align-items-center justify-content-center">
                            <lottie-player style="height: 150px;" src={createBlob(state.loading)}
                                background="transparent" speed="1" direction="1" mode="normal" loop
                                autoplay></lottie-player>
                        </div>
                        <div class="footer-credits text-center">
                            <a href="https://tamkin.app" target="_blank">
                                <span>Developed by Tamkin</span>
                                <img class="ms-2" src="{window.tamkin_src_base+'/images/link-external-dark.svg'}"
                                    alt="Tamkin">
                            </a>
                        </div>
                    </div>
                    <div style="display: none;" id="translationPreviewContainer" class="rounded-4">
                        <div class="h-100">
                            <img class="img-fluid pointer cursor-pointer"
                                src="{window.tamkin_src_base+'/images/video-preview.svg'}" alt="">
                        </div>
                    </div>
                    <div style="display: none;" class="translationPlayerContainer rounded-4 h-100">
                        <div style="height: 65%;" class="d-flex">
                            <div class="video-preview" style="width: 100%;">
                                <!--  <img class="h-100 w-100" src="./images/video-preview.svg" alt="">  -->
                                <iframe class="h-100 w-100" width="1309" height="480" src={state.currentVideoLink}
                                    title="تمكين" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                            <div class="ps-3 character-preview" style="display:none;">
                                <div class="rounded-3" style="background-color: var(--tp-light-2);">
                                    <!-- <img style="height: 217px" class="w-100" src="./images/fares.svg" alt=""> -->
                                    <tamkin-sdk-only-player style="height: 212px"
                                        class="d-inline-block w-100"></tamkin-sdk-only-player>
                                    <button class="btn btn-close-asl asl-video d-block w-100 rounded-3"
                                        style="background-color: #D9D9D9; color: #6d6d6d;" type="button">
                                        Close Character
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="p-3 mt-2 rounded-3 generated-text-video"
                            style="background-color: var(--tp-light-2);overflow-y: scroll;height: 35%;">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Suscipit corporis aperiam dignissimos unde dolores ipsum modi ab eos facere necessitatibus,
                            consectetur beatae. Obcaecati perspiciatis ratione, quo eaque fugit reiciendis sunt?
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Suscipit corporis aperiam dignissimos unde dolores ipsum modi ab eos facere necessitatibus,
                            consectetur beatae. Obcaecati perspiciatis ratione, quo eaque fugit reiciendis sunt?
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Suscipit corporis aperiam dignissimos unde dolores ipsum modi ab eos facere necessitatibus,
                            consectetur beatae. Obcaecati perspiciatis ratione, quo eaque fugit reiciendis sunt?
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Suscipit corporis aperiam dignissimos unde dolores ipsum modi ab eos facere necessitatibus,
                            consectetur beatae. Obcaecati perspiciatis ratione, quo eaque fugit reiciendis sunt?
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- modal translation audio -->
    <div class="modal fade translation-modal trans-audio" id="audioTranslationModal" tabindex="-1"
        aria-labelledby="audioTranslationModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="pt-3" id="audioTranslationModalLabel">
                        <p class=" font-18 font-w600">Video & Audio Translation in ASL</p>
                    </div>
                    <button type="button" data-bs-dismiss="modal" aria-label="Close">
                        <img src="{window.tamkin_src_base+'/images/icon-close-modal.svg'}" alt="Close">
                    </button>
                </div>
                <div style="height: 415px;" class="modal-body px-4 pb-4 pt-0">
                    <div style="display: block;" class="translationLoaderContainer rounded-4 pb-4 h-100">
                        <div style="height: calc(100% - 20px);"
                            class="translationModalLoader rounded-4 d-flex align-items-center justify-content-center">
                            <lottie-player style="height: 150px;" src={createBlob(state.loading)}
                                background="transparent" speed="1" direction="1" mode="normal" loop
                                autoplay></lottie-player>
                        </div>
                        <div class="footer-credits text-center">
                            <a href="https://tamkin.app" target="_blank">
                                <span>Developed by Tamkin</span>
                                <img class="ms-2" src="{window.tamkin_src_base+'/images/link-external-dark.svg'}"
                                    alt="Tamkin">
                            </a>
                        </div>
                    </div>
                    <div style="display: none;" class="translationPlayerContainer rounded-4 h-100">
                        <div class="variable-height" style="height: 40%;">
                            <div class="character-preview-container rounded-3 p-3 text-center"
                                style="background-color: var(--tp-light-2);">
                                <button style="display: none;"
                                    class="m-auto font-14 btn btn-primary btn-translate-asl asl-audio tp-bg-primary px-4 border-0 h-40px rounded-4">
                                    Open ASL
                                    <img class="mx-1" style="height: 21px;"
                                        src="{window.tamkin_src_base+'/images/asl.svg'}" alt="">
                                </button>
                                <div class="character-preview position-relative mh-100" style="display:none;">
                                    <img style="height: 242px;" class="w-100"
                                        src="{window.tamkin_src_base+'/images/fares.svg'}" alt="">
                                    <button
                                        class="btn btn-close-asl asl-audio d-block position-absolute top-0 start-0 rounded-3"
                                        style="background-color: #D9D9D9; color: #6d6d6d;" type="button">
                                        Close Character
                                    </button>
                                </div>
                            </div>
                            <div class="video-preview px-3 py-2 my-2 rounded-3 mh-100"
                                style="width: 100%;background: #4848488F;">
                                <!-- <audio controls class="w-100" src="./player_files/unit 1.mp3"></audio> -->
                                <audio controls class="w-100"></audio>
                            </div>
                        </div>
                        <div class="p-3 mt-2 rounded-3 variable-height-text generated-text-audio"
                            style="background-color: var(--tp-light-2);overflow-y: scroll;height: 60%;">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Suscipit corporis aperiam dignissimos unde dolores ipsum modi ab eos facere necessitatibus,
                            consectetur beatae. Obcaecati perspiciatis ratione, quo eaque fugit reiciendis sunt?
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Suscipit corporis aperiam dignissimos unde dolores ipsum modi ab eos facere necessitatibus,
                            consectetur beatae. Obcaecati perspiciatis ratione, quo eaque fugit reiciendis sunt?
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Suscipit corporis aperiam dignissimos unde dolores ipsum modi ab eos facere necessitatibus,
                            consectetur beatae. Obcaecati perspiciatis ratione, quo eaque fugit reiciendis sunt?
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            Suscipit corporis aperiam dignissimos unde dolores ipsum modi ab eos facere necessitatibus,
                            consectetur beatae. Obcaecati perspiciatis ratione, quo eaque fugit reiciendis sunt?
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <script>
        import "@lottiefiles/lottie-player";
        import loader from "/src/player_files/images/h.svg"
        import handload from '/src/player_files/json/handload.json';
        import hearts from '/src/player_files/json/hearts.json';
        import loading from '/src/player_files/json/loading.json';
        import twohand from '/src/player_files/json/twohand.json';

        export default {
            state: {
                handload: handload,
                hearts: hearts,
                loading: loading,
                twohand: twohand,

                currentVideoLink: null,
            },
            updateVideoLink(e) {
                this.update({
                    currentVideoLink: e.target.value
                })
            },
            handleLinkSubmit(e) {
                e.preventDefault();
                window.openTranslationModal(false)
                this.update({
                    currentVideoLink: this.getYouTubeEmbedURL(this.state.currentVideoLink)
                })
            },
            getYouTubeEmbedURL(url) {
                const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
                const match = url.match(regex);
                const videoId = match ? match[1] : null;
                return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
            },

            loadFile(path) {
                return 'https://tamkin.app' + path;
            },
            createBlob(json) {
                const blob = new Blob([JSON.stringify(json)], { type: 'application/json' });
                const url = URL.createObjectURL(blob);
                return url;
            },
        }
    </script>

    <style>
    </style>
</acc-translation>